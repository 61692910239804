import React from "react";
import {
  errorMarkLarge,
  errorMarkSmall,
  insufficientBalance,
} from "../../../../../assets/images";
import { Box } from "@mui/material";
import "./style.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CustomFixedButton from "../../../../../components/custom-fixed-button";

const PaymentFail = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const getLogo = (reason: string) => {
    return reason === "insufficient-balance"
      ? insufficientBalance
      : errorMarkLarge;
  };

  const getText = (reason: string) => {
    return reason === "insufficient_funds"
      ? "Your bank rejected your payment as you didn’t have enough money in your account. Please top up your balance and try again."
      : "Your bank couldn’t process your payment. Try again and if still experiencing issues contact support.";
  };

  const getMessage = (reason: string) => {
    return reason === "insufficient_funds"
      ? "Insufficient funds in your account"
      : "Unable to process payment.";
  };

  const paymentButtonHandler = async () => {
    // navigate to last page
    navigate(localStorage.getItem("failureRedirect"));
  }

  return (
    <>
      {
        !location.state || !location.state.paymentStatusResponse ? (
          <Navigate to="/" />
        ) : (
          <div
            id="payment_failed"
            className="d-flex flex-column justify-center items-center border-box"
          >
            <div className="d-flex flex-column p-1 flex-1 justify-center items-center">
              <img
                className="mb-3"
                src={getLogo(location.state?.paymentStatusResponse?.callBackPayload?.failure_reason ?? "insufficient_funds")}
                alt="insufficent-amount"
              />

              <p className="d-flex gap-05 items-center text-sm-2 mb-5 bordered shadow-extra-light p-1 width-100 border-box rounded-12 text-error">
                <img src={errorMarkSmall} alt="payment-failed" />
                {getMessage(location.state?.paymentStatusResponse?.callBackPayload?.failure_reason ?? "insufficient_funds")}
              </p>

              <p className="failed_message d-flex gap-05 items-center text-sm-2 bordered shadow-extra-light border-box p-1 mb-5 rounded-12">
                {getText(location.state?.paymentStatusResponse?.callBackPayload?.failure_reason ?? "insufficient_funds")}
              </p>

              <p className="text-new-sm text-center">Transaction ID: {location.state?.paymentStatusResponse?.callBackPayload?.payment_id ?? 'N/A'}</p>
            </div>

            <Box className="d-flex flex-column justify-center items-center width-100 gap-1">
              {/* <p className="color-blue fw-500 cursor-pointer" onClick={() => window.open(`${process.env.REACT_APP_HELP_URL}`, "_blank")}>Contact support</p> */}

              {/* <Button className="next-btn custom-btn btn-width px-15rem" fullWidth onClick={paymentButtonHandler}>
                Try again
              </Button> */}
            </Box>
            <CustomFixedButton text={'Try again'} disable={false} handleSubmit={paymentButtonHandler}
              upperParagraph={
                <Box className="p-2">
                  <p className="text-center cursor-pointer color-blue" onClick={() => window.open(`${process.env.REACT_APP_HELP_URL}`, "_blank")}>Contact Support</p>
                </Box>
              }
            />
          </div>
        )
      }
    </>
  );
};

export default PaymentFail;
