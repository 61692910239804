import { Box, Button } from "@mui/material";
import './style.scss';

const CustomFixedButton = ({ style={}, upperParagraph=<></>, paragraphHandler=() => {}, disable, icon = "",
        handleSubmit = () => {}, text, isDrawerButton=false, showUpperButton=false, upperButtonText="Later",
        upperButtonHandler= () => {}, upperButtonDisable=false, bottomJSX=<></>, borderTop=true, showButton=true}) => {
  return (
    <Box style={style} className={`fixed-button-container ${isDrawerButton ? '' : "mx-25-desktop-without-drawer"} ${borderTop === true ? 'border-t' : ''}`}>
      {
        upperParagraph
      }

      {showUpperButton && (<Box className={`${!isDrawerButton ? 'button-container-width mx-auto' : 'drawer-button-container-width'} d-flex justify-center items-center `}>
        <Button
          className={`custom-upper-button px-15rem ${isDrawerButton ? 'btn-width-full' : ''}`}
          disabled={upperButtonDisable}
          type={"submit"}
          onClick={upperButtonHandler}
          fullWidth
        >
          {upperButtonText}
        </Button>
      </Box>)
      }
      {showButton && <Box className={`${!isDrawerButton ? 'button-container-width mx-auto' : 'drawer-button-container-width'} d-flex justify-center items-center`}>
        <Button
          className={`custom-button px-15rem ${isDrawerButton ? 'btn-width-full' : ''}`}
          disabled={disable}
          type={"submit"}
          onClick={handleSubmit}
          fullWidth
        >
          {icon && <img src={icon} alt="icon" />} &nbsp; {text}
        </Button>
      </Box>}
      {bottomJSX}
    </Box>
  )
}

export default CustomFixedButton;